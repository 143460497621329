@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,900;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500&display=swap);
.buscar-pieza {
    font-family: 'Montserrat', sans-serif !important;
    max-width: 90vw !important;
    background-color: white;
    position: absolute;
    left: 5vw;
    bottom: -10vh;
    padding-left: 3vw !important;
    padding-top: 40px;
    padding-bottom: 60px;
    box-shadow: 0 0 10px;
}

.buscar-pieza-laptop {
    font-family: 'Montserrat', sans-serif !important;
    max-width: 90vw !important;
    background-color: white;
    position: absolute;
    left: 5vw;
    bottom: -100px;
    padding-left: 3vw !important;
    padding-top: 40px;
    padding-bottom: 60px;
    box-shadow: 0 0 10px;
}

.buscar-pieza-laptop-mdpi {
    font-family: 'Montserrat', sans-serif !important;
    max-width: 90vw !important;
    background-color: white;
    position: absolute;
    left: 5vw;
    bottom: -230px;
    padding-left: 3vw !important;
    padding-top: 40px;
    padding-bottom: 60px;
    box-shadow: 0 0 10px;
}

.buscar-pieza-laptop-touch{
    font-family: 'Montserrat', sans-serif !important;
    max-width: 90vw !important;
    background-color: white;
    position: absolute;
    left: 5vw;
    bottom: -100px;
    padding-left: 3vw !important;
    padding-top: 40px;
    padding-bottom: 60px;
    box-shadow: 0 0 10px;
}

.buscar-pieza-laptop-hp{
    font-family: 'Montserrat', sans-serif !important;
    max-width: 90vw !important;
    background-color: white;
    position: absolute;
    left: 5vw;
    bottom: -100px;
    padding-left: 3vw !important;
    padding-top: 40px;
    padding-bottom: 60px;
    box-shadow: 0 0 10px;
}

.buscar-pieza- {
    font-family: 'Montserrat', sans-serif !important;
    max-width: 90vw !important;
    background-color: white;
    position: absolute;
    left: 5vw;
    bottom: -60px;
    padding-left: 3vw !important;
    padding-top: 40px;
    padding-bottom: 60px;
    box-shadow: 0 0 10px;
}

.servicios{
    font-family: 'Montserrat', sans-serif !important;

}

.promociones{
    font-family: 'Montserrat', sans-serif !important;
    padding-top: 100px;
    padding-bottom: 80px;
}

.linea-sabiasque{
    background-color: #3266b1;
    width: 6rem;
    height: 4px;
    margin: 0px auto;
    margin-bottom: 50px;
}

.linea-sabiasque-2{
    background-color: #3266b1;
    width: 6rem;
    height: 4px;
    margin:auto;
}
.agotada-row{
    background-color: #F1CBD2;
}

.select-row{
    background-color: #BBCAE3;
}
.pedidoVinculado{
    background-color: rgb(187, 187, 187);
}

